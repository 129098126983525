import { Box } from "@components/Layout"
import styled from "styled-components"

export const Track = styled(Box)`
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }
`

Track.defaultProps = {
  display: "flex",
}
