import qs from "query-string"
import { ProductType } from "@screens/product"

type ImageArgs = {
  w?: number
  h?: number
  fit?: string
  crop?: string
}

interface Imgix {
  key: string
  type: ProductType
  args: ImageArgs
}

export const imgix = ({ key, type, args }: Imgix): string => {
  if (!args.w && !args.h) {
    throw new Error("You need to specify at least a width or a height")
  }

  const folder = type === "article" ? "articles" : "combinations"
  return `https://gassan-artikelplaatjes.imgix.net/875/${folder}/${key}.png?${qs.stringify(
    args
  )}`
}

export default imgix
