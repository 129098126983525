import styled, { css } from "styled-components"
import { Box } from "@components/Layout"
import * as S from "../elements"
import { Label } from "@components/Typography"
import { breakpoints } from "@config/theme"
import { lighten } from "polished"

// @ts-ignore
export const Content = styled(Box)`
  ${S.BasicContentStyle};
  width: 100%;

  svg {
    height: 11px;
    width: 17px;
  }
`

export const ProductCombinationGrid = styled(Box)`
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 1rem;
  grid-column-gap: 0;

  @media screen and (min-width: ${breakpoints.small}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (min-width: ${breakpoints.medium}) {
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
  }
  @media screen and (min-width: ${breakpoints.xlarge}) {
    grid-template-columns: repeat(4, 1fr);
  }
`

export const CatLabel = styled(Label)`
  position: absolute;
  top: 0;
  color: ${(p) => p.theme.colors.text.light};
  padding: 1rem 1.5rem;
`

const size = {
  small: "50%",
  medium: "75%",
  large: "90%",
}

export const ProductImage = styled(S.BaseImage)<{ imageSize: string }>`
  transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  transform-origin: center;
  max-height: 80%;
  width: ${(p) => size[p.imageSize]};
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;

  @media screen and (max-width: ${breakpoints.medium}) {
    height: 80%;
    max-height: 100%;
  }
`

export const HoverImage = styled(S.BaseImage)`
  opacity: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  transform: scale(0.95);
`

export const Anchor = styled.a<{ isLoading?: boolean }>`
  display: block;
  position: relative;
  color: ${(p) => p.theme.colors.text.dark};
  background: ${(p) => p.theme.colors.card.default};
  transition: background 0.1s ease-in-out;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background: ${(p) => lighten(0.05, p.theme.colors.card.active)};
  }
  &::before {
    padding-top: 130%;
    content: "";
    display: block;
  }

  ${(p) =>
    p.isLoading &&
    css`
      pointer-events: none;
    `}

  &:hover {
    ${ProductImage} {
      transform: translate(-50%, -50%) scale(1.05);
    }
    /* ${HoverImage} {
      opacity: 1;
      transform: scale(1);
    } */
    ${CatLabel} {
      color: ${(p) => p.theme.colors.text.dark};
    }
  }
`
