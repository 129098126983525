export const localeToPrismic = {
  nl: "nl-nl",
  en: "en-us",
}

export const socialUrls = {
  facebook: "https://www.facebook.com/ChoicesbyDL/",
  instagram: "https://www.instagram.com/choicesbydl/",
}

export const productGroupToUid = {
  ring: "rings",
  earring: "earrings",
  bracelet: "bracelets",
  necklace: "necklaces",
  stone: "gemstones",
  frame: "frames",
  base: "bases",
}
