import styled, { css, keyframes } from "styled-components"
import { Box } from "@components/Layout"
import { rgba } from "polished"

const loadingAnimation = keyframes`
0% {
  transform: translate3d(-50%, 0, 0);
}
100% {
  transform: translate3d(50%, 0, 0);
}
`

export const before = css`
  &::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    animation: ${loadingAnimation} 1.25s linear infinite;
    background: linear-gradient(
        to right,
        rgba(219, 221, 224, 0) 10%,
        ${(p) => rgba(p.theme.colors.card.default, 0.8)} 50%,
        rgba(219, 221, 224, 0) 90%
      )
      50% 50%;
  }
`

export const LoadingBar = styled(Box)`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  background-color: ${(p) => rgba(p.theme.colors.card.active, 1)};
  ${before};
`

LoadingBar.defaultProps = {
  height: "1rem",
  width: "100%",
}
