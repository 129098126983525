import React, { ButtonHTMLAttributes, FC } from "react"
import styled from "styled-components"
import { ArrowLeft, ArrowRight } from "@components/Icons"
import { breakpoints } from "@config/theme"

const Button = styled.button<{ direction: string }>`
  transition: all 0.4s ease;
  width: 2rem;
  height: 2rem;
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: currentColor;
  cursor: pointer;
  opacity: 1;
  background: ${(p) => p.theme.colors.card.default};
  outline: 0;
  border: 0;

  @media screen and (min-width: ${breakpoints.small}) {
    width: 3rem;
    height: 3rem;
    margin-right: ${(p) => (p.direction === "prev" ? "1rem" : 0)};
  }

  @media screen and (min-width: ${breakpoints.medium}) {
    width: 4rem;
    height: 4rem;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      opacity: 1;
    }
  }

  svg {
    fill: currentColor;
    width: 1.25rem;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background: ${(p) => p.theme.colors.card.active};
    }
  }

  &:active {
    opacity: 1;
    transform: scale(0.95);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.25;
  }
`

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  direction: "prev" | "next"
}

export const SliderNavButton: FC<Props> = ({
  direction,
  children,
  ...rest
}) => {
  return (
    <Button direction={direction} {...rest}>
      {direction === "prev" ? <ArrowLeft /> : <ArrowRight />}
    </Button>
  )
}

export default SliderNavButton
