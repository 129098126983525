import styled, { css } from "styled-components"
import { breakpoints } from "@config/theme"
import { TitleMStyle } from "@components/Typography/TextStyles/TitleM"

export const BaseImage = styled.img`
  position: absolute;
  height: 70%;
  width: 100%;
  top: calc(50% - 2rem);
  transform-origin: center;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
`

export const Anchor = styled.a`
  display: block;
  position: relative;
  color: ${(p) => p.theme.colors.text.dark};
  background: ${(p) => p.theme.colors.card.default};
  transition: background 0.1s ease-in-out;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  &:hover {
    background: ${(p) => p.theme.colors.card.active};
  }
  &::before {
    padding-top: 100%;
    content: "";
    display: block;
    @media screen and (min-width: ${breakpoints.small}) {
      padding-top: 130%;
    }
  }
`

export const BasicContentStyle = css`
  ${TitleMStyle};
  position: absolute;
  bottom: 0.5rem;
  padding: 0 1rem;
  z-index: 2;
  @media screen and (min-width: ${breakpoints.small}) {
    bottom: 1.5rem;
    padding-right: 1.5rem;
  }
`
