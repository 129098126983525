export const formatPrice = (cents: number, decimals = false): string => {
  if (decimals) {
    const price = (cents / 100).toFixed(2)
    return (
      "€ " +
      price
        .split(".")
        .join(",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    )
  } else {
    const price = (cents / 100).toString()
    return (
      "€ " +
      price
        .split(".")
        .join(",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    )
  }
}

export default formatPrice
