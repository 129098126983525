import React from "react"
import * as S from "./elements"
import { TitleS, Price } from "@components/Typography"
import { useIntl } from "@components/Intl"
import { LoadingBar } from "@components/LoadingBar"
import IntlLink, { IntlLinkProps } from "@components/Intl/IntlLink"
import formatPrice from "@lib/formatPrice"

interface Props extends IntlLinkProps {
  productImage?: string
  displayImage?: string
  meta?: string
  title?: string
  price?: number
  isLoading?: boolean
  imageSize?: "small" | "medium" | "large"
}

const ProductCombinationCard: React.FC<Props> = ({
  href: baseHref,
  productImage,
  displayImage,
  meta,
  as,
  title,
  price,
  isLoading = false,
  imageSize = "large",
}) => {
  const { messages } = useIntl()

  const inner = (
    <S.Anchor
      href={typeof as === "string" ? as : undefined}
      title={`${messages.global.view} ${title}`}
      isLoading={isLoading}
    >
      {!isLoading && productImage && (
        <S.ProductImage
          alt="Interchangeable jewelry"
          src={productImage}
          imageSize={imageSize}
        />
      )}
      {!isLoading && displayImage && <S.HoverImage src={displayImage} />}
      <S.CatLabel>{meta}</S.CatLabel>
      <S.Content>
        {isLoading ? (
          <>
            <LoadingBar height="1.5rem" mb="0.5rem" width="80%" />
            <LoadingBar height="1rem" width="40%" />
          </>
        ) : (
          <>
            <TitleS as="h3">{title}</TitleS>
            <Price>{price && formatPrice(price)}</Price>
          </>
        )}
      </S.Content>
    </S.Anchor>
  )

  return isLoading ? (
    inner
  ) : (
    <>
      <IntlLink href={baseHref} as={as}>
        {inner}
      </IntlLink>
    </>
  )
}

export default ProductCombinationCard
