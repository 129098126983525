import { RefObject, useEffect, useState } from "react"
import { getClosestIndex } from "./getClosestIndex"
export { Track } from "./Track"
export * from "./SliderNavButton"

let timer

type Args = {
  trackRef: RefObject<HTMLElement>
  scrollAmount?: number | "full-width"
  forceUpdate?: boolean
}

type Payload = {
  next: () => void
  prev: () => void
  goToIndex?: (newIndex: number) => void
  onTouchEnd: (event: React.TouchEvent<HTMLElement>) => void
  canMoveLeft: boolean
  canMoveRight: boolean
  index: number
}

export function useSlider({
  trackRef,
  scrollAmount = 1,
  forceUpdate,
}: Args): Payload {
  const [canMoveLeft, setCanMoveLeft] = useState(false)
  const [canMoveRight, setCanMoveRight] = useState(false)
  const [index, setIndex] = useState(0)

  const prev = () => {
    if (canMoveLeft && trackRef.current) {
      const itemWidth = (trackRef.current.children[0] as HTMLElement)
        .offsetWidth
      const left =
        scrollAmount === "full-width"
          ? trackRef.current.scrollLeft - trackRef.current.offsetWidth
          : trackRef.current.scrollLeft - itemWidth * scrollAmount
      scroll(left)

      if (scrollAmount !== "full-width") {
        setIndex(index - scrollAmount)
      }
    }
  }
  const next = () => {
    if (canMoveRight && trackRef.current) {
      const itemWidth = (trackRef.current.children[0] as HTMLElement)
        .offsetWidth
      const left =
        scrollAmount === "full-width"
          ? trackRef.current.scrollLeft + trackRef.current.offsetWidth
          : trackRef.current.scrollLeft + itemWidth * scrollAmount

      scroll(left)
      if (scrollAmount !== "full-width") {
        setIndex(index + scrollAmount)
      }
    }
  }

  const goToIndex = (newIndex: number) => {
    if (trackRef.current) {
      const itemWidth = (trackRef.current.children[0] as HTMLElement)
        .offsetWidth
      const left =
        scrollAmount === "full-width"
          ? trackRef.current.offsetWidth
          : itemWidth * newIndex
      scroll(left)
      if (scrollAmount !== "full-width") {
        setIndex(newIndex)
      }
    }
  }
  const onTouchEnd = () => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if (trackRef && trackRef.current && scrollAmount !== "full-width") {
        const closestIndex = getClosestIndex(trackRef.current)
        setIndex(closestIndex)
      }
    }, 500)
  }

  const scroll = (left: number) =>
    trackRef.current &&
    trackRef.current.scrollTo({ top: 0, left, behavior: "smooth" })

  useEffect(() => {
    if (!trackRef.current) {
      return
    }
    const children = Array.from(trackRef.current.children)
    const firstChild = children[0]
    const lastChild = children[children.length - 1]
    // Check if the first or last child is or isn't intersecting.
    // If it is, it means we cant scroll further to the left or right
    const observe = (entries) => {
      entries.forEach((entry) => {
        if (entry.target === firstChild) {
          setCanMoveLeft(!entry.isIntersecting)
        }
        if (entry.target === lastChild) {
          setCanMoveRight(!entry.isIntersecting)
        }
      })
    }

    // Create a new IntersectionObserver and observe the first and the last child
    const observer = new IntersectionObserver(observe, {
      root: trackRef.current,
      rootMargin: "0px",
      threshold: 0.9,
    })

    if (firstChild) {
      observer.observe(firstChild)
    }
    if (lastChild) {
      observer.observe(lastChild)
    }

    // Clear the timer if set
    return () => {
      clearTimeout(timer)
    }
  }, [trackRef, forceUpdate]) // Force a rerender if forceUpdate changes value

  return { next, prev, goToIndex, onTouchEnd, canMoveLeft, canMoveRight, index }
}
